.formTitle[data-v-c262ece2] {
  font-weight: bold;
  margin-bottom: 20px;
}
.w200[data-v-c262ece2] {
  width: 190px;
  margin: 0;
}
.drawerHead[data-v-c262ece2] {
  display: flex;
  align-items: center;
}
.tab[data-v-c262ece2] .el-tabs__nav-wrap {
  width: 100%!important;
}
.tab[data-v-c262ece2] .el-button+.el-button {
  margin-left: 5px!important;
}
.cancelBtn[data-v-c262ece2] {
  left: 96%!important;
}
